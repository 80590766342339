<template>
        <div id="bottomLeftChart" style="height:98%;margin-top: 5%;"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.drawPie();
  },
  methods: {
    drawPie() {
      let category = ['守望驿站','温暖包','净水计划','壹乐园-音乐教室','壹乐园-音乐运动汇','儿童服务站','一个鸡蛋','XIN益佰"一个鸡蛋的暴走"','妇幼守望者公益岗'];
      let rateData = [13152,36565,30000,10256,34505,461293,6174,350,1238]; //服务人次
      let lineData = [776,0,150,18,81,39056,0,40,107]; //活动场次
      let barData = [0,46,7,0,0,138,7,3,0];  //社会参与数
      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize: 16,
            fontWeight: "normal"
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            }
          }
        },
        legend: {
          data: ["总社会参与数", "总活动场次", "总服务人次"],
          textStyle: {
            color: "#B4B4B4"
          },
          top: "0%"
        },
        grid: {
          x: "10%",
          width: "80%",
          y: "4%",
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            splitLine: {show: false},
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },

            axisLabel: {
              formatter: "{value} "
            }
          },
          {
            splitLine: {show: false},
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisLabel: {
              formatter: "{value} "
            }
          }
        ],
        series: [
          {
            name: "总服务人次",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#F02FC2"
              }
            },
            data: rateData
          },

          {
            name: "总社会参与数",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color:'#3EACE5',
              }
            },
            data: barData
          },

          {
            name: "总活动场次",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color:'rgba(156,107,211,0.5)',
              }
            },
            z: -12,
            data: lineData
          }
        ]
      };
      let myChart = this.$echarts(this.$el);

      myChart.clear();
      myChart.resize();
      myChart.setOption(option);
    },
  }
};
</script>

<style lang="less" scoped>
#bottomLeft {
  padding: 1.5rem 1rem;
  //height: 520px;
  width:95%;
  //min-width: 300px;
  border-radius: 5px;

  .bg-color-black {
    height: 485px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .chart-box {
    margin-top: 1rem;
    width: 170px;
    height: 170px;

    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>